import React, { Component } from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import bannerLogo from '../../static/logo_banner.png'

class Navigation extends Component {
  render() {
    const { pageName } = this.props
    const navItems = [
      {
        pageName: 'home',
        name: 'Home',
        path: '/'
      },
      {
        pageName: 'about',
        name: 'About',
        path: '/about'
      },
      {
        pageName: 'blog',
        name: 'Blog',
        path: '/blog/'
      },
      {
        pageName: 'books',
        name: 'Books + Courses',
        path: '/books'
      },
      {
        pageName: 'projects',
        name: 'Projects',
        path: '/projects'
      },
    ];
    let navList = navItems.map(function (item) {
      let navStyle = styles.navigationItem;
      if (item.pageName == pageName) {
        navStyle = styles.currentPage
      }
      return (
        <li className={navStyle} key={item.pageName}>
          <Link to={item.path}>{item.name}</Link>
        </li>
      )
    });
    return (
      <div>
        <div style={{display: "inline-block"}}>
          <img src={bannerLogo} alt="Coffee In Code Out Development, LLC" style={{paddingTop:25}}/>
        </div>
        <nav role="navigation" style={{
          display: "inline-block",
          paddingTop: 0,
          verticalAlign: "bottom",
          marginLeft:50
        }}>

          <ul className={styles.navigation}>
            {navList}
          </ul>
        </nav>
      </div>
    )
  }
}

export default Navigation;